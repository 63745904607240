
function initCommonScripts() {
  'use strict';

  // CONST
  window.PSWP = {
    TMPL: document.getElementById('pswp'),
    UI: PhotoSwipeUI_Default
  };

  window.$BODY = $(document.body);

  (function() {
    var images = '.card-main-image img, #pswp img';

    $BODY.on('contextmenu', images, function() {
      return false;
    });
  })();

  $BODY.on('yiiListViewUpdated', function() {
    var $catalog = $('.catalog');
    var scrollSpeed = Math.abs( $(window).scrollTop() ) * 0.3;

    $('.js-lazy-pic').unveil(200);

    $(window).scrollTo( $catalog, {
      duration: scrollSpeed
    });
  });

  $('.js-lazy-pic').unveil(200);

  $BODY.on('panelUpdated', function() {
    $('#panel input[type="tel"], .tel-inp').mask('+7 (999) 999-99-99', {autoclear: false});
  });

  $('#panel').on('click', '.panel-fast-order', function(e) {
    e.preventDefault();

    var input = $(this).closest('.products-panel-one-click').find('.panel-fast-order-phone');

    if( !input.val() ) {
      return dialog('alert', 'Заполните поле телефон', {
        labels: {
          ok: 'Ok'
        }
      });
    }

    else if( input.val().indexOf('_') != -1 ) {
      return dialog('alert', 'Заполните поле телефон полностью', {
        labels: {
          ok: 'Ok'
        }
      });
    }

    var form = $('#fast-order-form-basket').clone();
    var inputForm = form.find('input[name=Order\\[phone\\]]');
    inputForm.val(input.val());

    var data = {'fastorder' : $(this).data(), 'action' : 'fastOrder'};
    $.post(form.attr('action'), $.param(data) + '&' + form.serialize() , function(resp) {
      checkResponse(resp, form);
    }, 'json');
  });


  $('.main-features li').on('mouseenter', function(){
    var text = $(this).find('.features-menu-dropdown').attr('data-text');
    $(this).find('.features-menu-dropdown').html( '<!--noindex-->' + text + '<!--/noindex-->');
  });


  $('#panel').on('click', '.panel-fast-favorite', function(e) {
    e.preventDefault();

    var input = $(this).closest('.products-panel-one-click').find('.panel-fast-favorite-phone');

    if( !input.val() ) {
      return dialog('alert', 'Заполните поле телефон', {
        labels: {
          ok: 'Ok'
        }
      });
    }

    else if( input.val().indexOf('_') != -1 ) {
      return dialog('alert', 'Заполните поле телефон полностью', {
        labels: {
          ok: 'Ok'
        }
      });
    }

    var form = $('#callback_form').clone();
    var inputForm = form.find('input[name=Callback\\[phone\\]]');
    inputForm.val(input.val());

    var data = {'action' : 'index'};
    $.post(form.attr('action'), $.param(data) + '&' + form.serialize() , function(resp) {
      checkResponse(resp, form);
    }, 'json');
  });


  $('.vitrine-tabs, .card-right-tabs').tabs({
    activate: function(event, ui) {
      setTimeout(function() {
        $(window).trigger('lookup'); // images search trigger for unveil plugin
      }, 200);
    }
  });

  $BODY.on('ready yiiListViewUpdated updateSelectionFilter', function() {
    $('select').selectric({
      onOpen: function() {
        $(this).closest('.selectricWrapper')
          .find('.selectricScroll')
          .perfectScrollbar();
      }
    });
  }).trigger('ready');

  $('input[type="tel"], .tel-inp').mask('+7 (999) 999-99-99', { autoclear: false });

  // $('input[type="date"], .date-inp').datepicker({
  //   changeMonth: true,
  //   changeYear: true,
  //   yearRange: '1900:2015',
  //   onChangeMonthYear: function() {
  //     setTimeout(function() {
  //       $('.ui-datepicker-month, .ui-datepicker-year').selectric({
  //         onOpen: function() {
  //           $(this).closest('.selectricWrapper').find('.selectricScroll').perfectScrollbar();
  //         }
  //       });
  //     }, 0);
  //   },
  //   beforeShow: function() {
  //     setTimeout(function() {
  //       $('.ui-datepicker-month, .ui-datepicker-year').selectric({
  //         onOpen: function() {
  //           $(this).closest('.selectricWrapper').find('.selectricScroll').perfectScrollbar();
  //         }
  //       });
  //     }, 0);
  //   }
  // });

  $BODY.on('click.overlay', '.js-overlay-loader', function(e) {
    e.preventDefault();
    var $target = $( $(this).attr('href') );
    $.overlayLoader(true, {
      node: $target,
      onShow: function() {
        setTimeout(function() {
          $target.find('.autofocus-inp').focus();
          $target.find('.autoselect-inp').select();
        }, 300);
      }
    });
  });

  $('.js-video-overlay-loader').on('click.overlay', function(e) {
    e.preventDefault();

    var $this = $(this);
    var $target = $($this.attr('href'));
    var iframe;
    if ($this.data('width')) {
      iframe = [
        '<iframe frameborder="0" allowfullscreen',
          'src="' + $this.data('video-src') + '"',
          'width="' + $this.data('video-width') + '"',
          'height="' + $this.data('video-height') + '"',
        '</iframe>'
      ];
    } else {
      iframe = [
        '<p class="video">',
          '<iframe frameborder="0" allowfullscreen',
            'src="' + $this.data('video-src') + '"',
          '</iframe>',
        '</p>'
      ];
    }

    $.overlayLoader(true, {
      node: $target,

      onShow: function() {
        if ($target.find('.video').length) {
          $target.find('.video').remove();
        }
        $target.append( iframe.join(' '));
      },

      onHide: function() {
        $target.find('iframe').remove();
      }
    });
  });

  $('.js-show-3d').click(function(e) {
    e.preventDefault();
    var $target = $( $(this).attr('href') );
    $.overlayLoader(true, {
      node: $target,
      onShow: function() {}
    });
  });

  // fire popup by its #hash (without GET-parameters)
  $(document).ready(function () {
    var hash = window.location.hash;
    if (hash == '') return;
    if (hash.substr(hash.length - 8) != 'popup-3d') return;

    var $popup = $('#popup-3d');
    $.overlayLoader(true, {
      node: $popup,
      hideSelector: '.js-popup-close'
    });
  });



  // laminate classes & additional content popups
  $(document.body).on('click.overlay', '.js-additional-content-popup, .js-laminate-classes-popup', function (e) {
    e.preventDefault();
    var $target = $( $(this).attr('href') );
    $.overlayLoader(true, {
      node: $target
    });

    $target.html('');
    $.post($(this).data('url'), {}, function( response ) {
      $target.html(response);
    });
  });


  $BODY.on('click.overlay', '.js-close-popup', function(e) {
    e.preventDefault();
    $.overlayLoader(false, $(this).closest('.popup'));
  });

  $BODY.on('click.autoselect-inp', '.autoselect-inp', function() {
    $(this).select();
  });


  // spinner inp
  $BODY.on('mousedown.spinner', '.spinner-inp .icon', function() {
    var $icon = $(this);
    var $input = $icon.siblings('.inp');
    var incTimeout, incInterval, decTimeout, decInterval;

    $(this)
      .on('mouseup.spinner', clearAll)
      .on('mouseleave.spinner', $icon, clearAll);

    if ($icon.hasClass('spinner-down-icon')) {
      decVal(); decTimeout = setTimeout(function() {
        decInterval = setInterval(decVal, 70);
      }, 300);
    }

    if ($icon.hasClass('spinner-up-icon')) {
      incVal(); incTimeout = setTimeout(function() {
        incInterval = setInterval(incVal, 70);
      }, 300);
    }

    function incVal() {
      $input.val(parseInt($input.val()) + 1).change();
    }

    function decVal() {
      if (parseInt($icon.siblings('.inp').val()) > 1) {
        $input.val(parseInt($input.val()) - 1).change();
      }
    }

    function clearAll() {
      clearTimeout(decTimeout); clearInterval(decInterval);
      clearTimeout(incTimeout); clearInterval(incInterval);
    }
  });

  // rotator nav
  // (function() {
  //   var $mainBanner = $('#main-banner');
  //   var $slideshowNav = $mainBanner.find('.slideshow-nav-inner');

  //   $mainBanner.find('.cycle-slideshow img')
  //     .not('.cycle-sentinel')
  //     .each(function(index) {
  //       var $this = $(this);
  //       $slideshowNav.find('span:eq(' + index + ')')
  //         .html( '<a href="' + $this.data('href') + '">' + $this.attr('alt') + '</a>');
  //     });
  // }());

  // (function() {
  //   $('.js-tooltip').each(function() {
  //     var $this = $(this);
  //     $this.wrap('<div class="tooltip">');
  //     $this.closest('.tooltip').append('<span class="tooltip-text">' + $this.data('tooltip-text') + '</span>');
  //   });
  // }());

  (function() {
    $BODY.on('click', '.js-tooltip-inside .tooltip-close', function() {
      var $self = $(this);
      $self.closest('.js-tooltip-inside').removeClass('active');
      $BODY.off('click.tooltip-inside');
    });
  }());

  // toggle product view
  (function() {
    $BODY
      .on('click', '.product .js-full-view', function() {
        var $product = $(this).closest('.product');
        var $fullView = $product.find('.full-view-container');

        if ( $fullView.html() === '' ) {
          $.mouseLoader(true);
          $.post( $(this).data('url'), null, function (content) {
            $product
              .removeClass('short-view')
              .addClass('full-view')
              .siblings('.product')
                .removeClass('full-view')
                .addClass('short-view');
            $fullView.replaceWith(content);
            $.mouseLoader(false);
          });
        } else {
          $product
            .removeClass('short-view')
            .addClass('full-view')
            .siblings('.product')
              .removeClass('full-view')
              .addClass('short-view');
        }
      })
      .on('click', '.product .js-short-view', function() {
        $(this).closest('.product')
          .removeClass('full-view')
          .addClass('short-view');
      });
  }());

  (function() {
    var container = '#card-carousel';

    if ( $('.jcarousel li', container) ) {}

    $('.jcarousel').jcarousel({
      transitions: true,
    });

    $('.jcarousel-control-prev')
      .on('jcarouselcontrol:active', function() {
        $(this).removeClass('inactive');
      })
      .on('jcarouselcontrol:inactive', function() {
        $(this).addClass('inactive');
      })
      .jcarouselControl({
        target: '-=1'
      });
    $('.jcarousel-control-next')
      .on('jcarouselcontrol:active', function() {
        $(this).removeClass('inactive');
      })
      .on('jcarouselcontrol:inactive', function() {
        $(this).addClass('inactive');
      })
      .jcarouselControl({
        target: '+=1'
      });
  }());

  (function() {
    var container = '#certificates-carousel';

    if ( $('.jcarousel li', container) ) {}

    $('.jcarousel').jcarousel({
      transitions: true,
    });

    $('.jcarousel-control-prev')
      .on('jcarouselcontrol:active', function() {
        $(this).removeClass('inactive');
      })
      .on('jcarouselcontrol:inactive', function() {
        $(this).addClass('inactive');
      })
      .jcarouselControl({
        target: '-=1'
      });
    $('.jcarousel-control-next')
      .on('jcarouselcontrol:active', function() {
        $(this).removeClass('inactive');
      })
      .on('jcarouselcontrol:inactive', function() {
        $(this).addClass('inactive');
      })
      .jcarouselControl({
        target: '+=1'
      });

    if ($(container).find('.jcarousel-control.inactive').length == 2) {
      $(container).find('.jcarousel-control').hide();
    }
  }());

  (function() {
    var container = '#video-carousel';
    var size = $(window).width() > 480 ? 2 : 1;

    if ( $('.jcarousel li', container).length > size ) {
      $('.jcarousel-control').show();
    }

    $('.jcarousel', container).on('jcarousel:create jcarousel:reload', function() {
      var element = $(this),
          width = size == 1 ? element.innerWidth() : element.innerWidth() / 2 - 20;
      element.jcarousel('items').css('width', width + 'px');
    }).jcarousel({
      transitions: true,
    });

    $('.jcarousel-control-prev', container)
      .on('jcarouselcontrol:active', function() {
        $(this).removeClass('inactive');
      })
      .on('jcarouselcontrol:inactive', function() {
        $(this).addClass('inactive');
      })
      .jcarouselControl({
        target: '-=1'
      });
    $('.jcarousel-control-next', container)
      .on('jcarouselcontrol:active', function() {
        $(this).removeClass('inactive');
      })
      .on('jcarouselcontrol:inactive', function() {
        $(this).addClass('inactive');
      })
      .jcarouselControl({
        target: '+=1'
      });
  }());

  // gallery + card gallery
  (function() {
    var settings = {};

    $('.js-pswp-images').each(createGallery);

    function createGallery() {
      var elem = this;
      var $items = $(elem).find('li:not(.item-3d) > a');
      var sources = [];

      $.each($items, function(k, v) {
        var $this = $(this);
        var img = new Image();
        var name = $this.data('item-name');
        var href = $this.data('item-href');

        $.data(this, 'image-id', k);

        img.src = $(this).attr('href');
        img.addEventListener('load', function() {
          sources[k] = {
            src: img.src,
            w: this.width,
            h: this.height
          };

          if (name && href) sources[k].title = '<a href="' + href + '">' + name + '</a>';
        });
      });

      $items.on('click', function(ev) {
        ev.preventDefault();
        settings.index = $.data(this, 'image-id');
        var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, sources, settings);
        pswp.init();
      });
    }
  }());

  // single zoom
  (function() {
    $('.js-pswp-zoom').on('click', function(e) {
      e.preventDefault();
      var img = new Image();
      var images = [];
      var D = $.Deferred();
      img.src = $(this).attr('href');

      img.onload = function() {
        images.push({
          src: img.src,
          w: this.width,
          h: this.height
        });

        return D.resolve();
      };

      $.when(D).then(function() {
        var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, images, {
          shareEl: false,
          arrowKeys: false
        });

        pswp.init();
      });
    });
  }());

  (function() {
    $('.js-mobile-zoom').on('click', function(e) {
      e.preventDefault();
      if ( $(window).width() > 480 ) return;
      var img = new Image();
      var images = [];
      var D = $.Deferred();
      img.src = $(this).attr('href');

      img.onload = function() {
        images.push({
          src: img.src,
          w: this.width,
          h: this.height
        });

        return D.resolve();
      };

      $.when(D).then(function() {
        var pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, images, {
          shareEl: false,
          arrowKeys: false,
          mainClass: 'popup-gallery'
        });

        pswp.init();
      });
    });
  }());

  // product gallery
  $BODY.on('click.pswp-zoom', '.js-product-zoom', function(e) {
    e.preventDefault();

    var $zoom = $(this);
    var sources = $zoom.data('gallery');
    var images = [];
    var D = $.Deferred();
    var pswp;

    // никак не реагируем на клики пока грузятся картинки
    if ($zoom.hasClass('js-active')) return;

    $zoom.addClass('js-active');

    $.each(sources, function(key, val) {
      var img = new Image();
      img.src = val;

      img.addEventListener('load', function() {
        return setTimeout(function() {
          images[key] = {
            src: this.src,
            w:   parseInt(this.width),
            h:   parseInt(this.height)
          };

          if (key === sources.length - 1) return D.resolve();
        }.bind(this), 75);
      });
    });

    window.onerror = function() {
      $('.js-product-zoom.js-active').removeClass('js-active');
    };

    $.when(D).then(function() {
      setTimeout(function() { // FIXME избавиться от таймаута
        pswp = new PhotoSwipe(PSWP.TMPL, PSWP.UI, images, {});
        setTimeout(function() {
          pswp.init();
          $zoom.removeClass('js-active');
        }, 50);
      }, 125);
    });
  });

  $('.js-product-zoom').each(function() {
    var $icon = $(this);
    var sources = $icon.data('gallery');

    if (sources.length > 1) {
      var imagesAmount = sources.length + ' фото';
      $icon.add( $icon.closest('.product-image').find('a img') ).attr('title', imagesAmount);
    }
  });

  // card tabs nav
  (function() {
    $('.card-tabs-menu').on('click', 'a', function(e) {
      e.preventDefault();

      var $link = $(this);
      var $target = $( $link.attr('href') );
      var duration = Math.abs( $target.offset().top - $(window).scrollTop() ) * 0.3;

      $(window).scrollTo($target, {
        duration: duration
      });
    });
  }());

  // Alphabet filter
  (function() {
    $('.filter-alphabet').find('.letter, .btn').click(function(e) {
      e.preventDefault();
      var $this = $(this),
          letter = $this.data('letter');
      $this.addClass('active').siblings().removeClass('active');
      if ( letter != 'all' ) {
        $('.wood-type').stop(true, true).hide()
          .filter('[data-letter="' + letter + '"]').stop(true, true).fadeIn();
      } else {
        $('.wood-type').stop(true, true).fadeIn();
      }
    });
  }());

  // Intro spoiler
  (function() {
    $('.intro-spoiler-anchor').click(function(e) {
      e.preventDefault();
      var $this = $(this),
          $spoiler = $this.closest('.intro-spoiler'),
          $spoilerBody = $spoiler.find('.intro-spoiler-body');
      $this.hide();
      $spoilerBody.fadeIn();
    });
  }());

  // lift
  (function() {
    var $lift = $('.js-lift');

    $lift.on('click', function() {
      $(window).scrollTo($BODY, {
        duration: Math.abs($BODY.offset().top - $(window).scrollTop()) * 0.3
      });
    });

    $(window).on('scroll.lift', function(e) {
      if ($(this).scrollTop() > 500) {
        $lift.removeClass('is-hidden');
      } else {
        $lift.addClass('is-hidden');
      }
    });
  }());

  $BODY.on('ready yiiListViewUpdated', function() {
    setTimeout(function() {
      var $collapsedFilters = $('.side-filter.collapsed');

      if ($collapsedFilters.find(':checkbox:checked').length) {
        $collapsedFilters.removeClass('collapsed');
        $('.js-side-filter-toggle')
          .addClass('active')
            .find('.js-text')
            .html('скрыть');
      }
    }, 0);
  }).trigger('ready');

  $('.filter-colors .color-box input').change(function() {
    if ( $(this).prop('checked') ) {
      $(this).closest('label').addClass('active');
    } else {
      $(this).closest('label').removeClass('active');
    }
  });

  // $BODY.on('click', '.js-side-filter-toggle', function() {
  //   var $this = $(this);
  //   var $filters = $this.nextAll('.side-filter');
  //   var $text = $this.find('.js-text');

  //   if ( $this.hasClass('active') ) {
  //     $this.removeClass('active');
  //     $filters.addClass('collapsed');
  //     $text.html('показать');
  //     sessionStorage.setItem('extended-filters-key', 'no');
  //   } else {
  //     $this.addClass('active');
  //     $filters.removeClass('collapsed');
  //     $text.html('скрыть');
  //     sessionStorage.setItem('extended-filters-key', 'yes');
  //   }
  // });

  $BODY.on('ready yiiListViewUpdated', function() {
    var $activeMenuItem = $('.side-filter li.active');

    if ($activeMenuItem.index() >= 10) {
      var $thisFilter = $activeMenuItem.closest('.side-filter');
      var $thisToggleButton = $thisFilter.find('._toggle-filters-view');

      $thisFilter.addClass('show-all');
      $thisToggleButton.html($thisToggleButton.data('hide-text'));
    }

    $BODY.on('click.toggle-filter', '._toggle-filters-view', function(e) {
      e.preventDefault();
      var $this = $(this);
      var $filter = $this.closest('.side-filter');

      if (!$filter.hasClass('show-all')) {
        $filter.addClass('show-all');
        $this.html($this.data('hide-text'));
      } else {
        $filter.removeClass('show-all');
        $this.html($this.data('show-text'));
      }
    });

    // if ($(window).width() > 768 && !sessionStorage.getItem('extended-filters-key')) {
    //   sessionStorage.setItem('extended-filters-key', 'yes');
    // }

    // if (sessionStorage.getItem('extended-filters-key') === 'yes') {
    //   setTimeout(function() {
    //     $('.js-side-filter-toggle')
    //       .addClass('active')
    //       .nextAll('.side-filter')
    //         .removeClass('collapsed');
    //     $('.js-side-filter-toggle').find('.js-text').text('Скрыть');
    //   }, 100);
    // }
  }).trigger('ready');

  if (isMobile) {
    $('body').on('change', '.short-filters-form select', function() {
      $('#left-filter').fadeOut();
    });
  }

  if (isMobile) {
    $('.menu').each(function() {
      var $menu = $(this);

      if (!$menu.find('li ul').length) return;

      $menu.on('click.mobile-menu', 'a', function(e) {
        var $link = $(this);
        var $linkParrent = $link.closest('li');
        var isEmptyLink = !$link.attr('href');

        if ($linkParrent.find('ul').length) {
          e.preventDefault();

          if ($linkParrent.hasClass('active')) {
            $linkParrent.removeClass('active');
            if (isEmptyLink) return;
            window.location = $link.attr('href');
          }

          else {
            $linkParrent.addClass('active');
            $linkParrent.siblings().removeClass('active');
          }

          $BODY.on('click.mobile-menu', function(e) {
            if (!$(e.target).closest($linkParrent).length) {
              $linkParrent.removeClass('active');
              $BODY.off('click.mobile-menu');
            }
          });
        }
      });
    });
  }

  $('.js-mobile-info-selector').change(function() {
    window.location = $(this).val();
  });

  setTimeout(function() {
    var $wrap = $('.MultiFile-wrap');

    var MultiFile = MultiFile || false;

    $wrap.find('.MultiFile-applied')
      .wrap('<div class="file-inp" />');

    $wrap.find('.MultiFile-list')
      .detach()
      .prependTo($wrap);


    if (MultiFile) {
      MultiFile.on('afterFileAppend', function() {
        alert('file append')
      });
    }
  }, 0);

  function getBrowserInfo() {
    var t,v = undefined;

    if (window.chrome) t = 'Chrome';
    else if (window.opera) t = 'Opera';
    else if (document.all) {
      t = 'IE';
      var nv = navigator.appVersion;
      var s = nv.indexOf('MSIE')+5;
      v = nv.substring(s,s+1);
    }
    // else if (navigator.appName) t = 'Netscape';

    return {type:t,version:v};
  }

  function bookmark(a,e){
    var url = window.document.location;
    var title = window.document.title;
    var b = getBrowserInfo();

    if (b.type == 'IE' && 8 >= b.version && b.version >= 4) {
      e.preventDefault();
      window.external.AddFavorite(url,title);
    } else if (b.type == 'Opera' || b.type == 'Netscape' ) {
      a.attr('href',url);
      a.attr('rel',"sidebar");
      a.attr('title',title);
    } else {
      e.preventDefault();
      alert("Нажмите CTRL-D, чтобы добавить страницу в закладки.");
    }
  }

  $('.js-site-fav').on('click', function(e) {
    bookmark( $(this), e );
  });

  $('.mobile-toggler-caption').click(function(e) {
    e.preventDefault();

    $(this).closest('.mobile-toggler').toggleClass('collapsed');
  });

  // Сворачивание шапки на мобильных при скролле
  var toggleHeaderInit = function() {
    var $header = $('#header'),
        scrollPos = 0,
        startTop = 0;

    $header.addClass('fixed');

    // $(window).on('touchstart.header', function() {
    //   startTop = $(window).scrollTop();
    // });

    // $(window).on('touchend.header', function() {
    //   var scrollTop = $(window).scrollTop();
    //   if ( scrollTop < startTop ) {
    //     $header.removeClass('collapsed');
    //     scrollPos = scrollTop;
    //   } else {
    //     if ( scrollTop > scrollPos + 300 ) {
    //       $header.addClass('collapsed').find('.search-block').slideUp();
    //     }
    //   }
    // });
  }

  if ( $(window).width() <= 800 ) {
    toggleHeaderInit();
  }

  $(window).on('resize', function() {
    if ( $(window).width() <= 800 ) {
      toggleHeaderInit();
    } else {
      $(window).off('touchstart.header touchend.header');
      $('#header').removeClass('fixed collapsed');
    }
  });

  //GA CALLBACKS
  $('.js-overlay-loader._header-callback').on('click', CALLBACKS.gaCall1);
  $('.one-click._card-one-click-button').on('click', CALLBACKS.gaQuickOrder);


  if (isMobile) {
    $('.main-menu').on('click', 'a', function(e) {
      //e.preventDefault();
      // if ( $(this).siblings('ul').length && !$(this).hasClass('clicked') ) {
      //   e.preventDefault();
      //   $(this).addClass('clicked');
      // } else {
      //   return;
      // }
      var $this = $(this),
          $li = $this.closest('li'),
          $menu;
      if ( $li.hasClass('has-dropdown') ) {
        e.preventDefault();
        $menu = $this.data('menu') ||
                $('<div class="popup menu-item-popup"><a href="#" class="close js-close-popup"></a></div>')
                .append( $li.find('ul') )
                .append( $('<div class="center" />').html( $this.clone().addClass('btn black-btn h32-btn') ));
        $this.data('menu', $menu);
        $.overlayLoader(true, $menu);
      }
    });
  }

  $('.js-toggle-all-products').click(function(e) {
    e.preventDefault();
    $(this).toggleClass('collapsed').closest('.vitrine-wrapper').find('.catalog').toggleClass('collapsed-vitrine');
  });

  $(document).on('click.scroll-to', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this);
    var $elemToScroll = $($lnk.attr('href'));
    var speed = $lnk.data('speed') || 300;
    var offset = $lnk.data('offset') || 0;

    if ( $(window).width() <= 800 ) {
      offset -= $('#header').height();
    }

    $(window).scrollTo($elemToScroll, {duration: speed, offset: offset});
  });

  $('.js-play-video').click(function clickHandler(event) {
    event.preventDefault();
    const $this = $(this);
    const $videoLink = $this.prop('href');
    const $video = $('<iframe width="100%" height="100%" class="parking-video" src="'+ $videoLink +'" frameborder="0" allowfullscreen></iframe>');
    $this.parent().addClass('with--video');
    $this.replaceWith($video);
  });
}

$(function() {
  initCommonScripts();
});
